import { useContext, useEffect, useRef } from "react"
import FcmMessageContext from "../providers/FcmMessageProvider"

export const useFcmMessage = () => {
    const fcmContext = useContext(FcmMessageContext);
    const callbackRef = useRef();

    useEffect(() => {
        if (typeof callbackRef.current === 'function') {
            callbackRef.current(fcmContext);
        }
    }, [fcmContext]);

    const onFcmMessageTap = (callback) => {
        callbackRef.current = callback;
    };

    return { onFcmMessageTap };
}