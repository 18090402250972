import React, { useEffect, useMemo } from "react";
import { useRouteError } from "react-router-dom";
import Box from "../../atoms/Box";
import Button from "../../atoms/Button";
import { EventEmitter } from "../../../helper/JumboEventEmitter";
import { APP_LOADING_TEXT } from "../../../constant/app.constant";

const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    //Error Logs
    emitEvent("WEB_APP_CRASH");
  }, []);

  //Reload Screen
  function tryAgain() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  const slogan = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * APP_LOADING_TEXT.length);
    return APP_LOADING_TEXT[randomIndex];
  }, []);

  function emitEvent(type) {
    const connection =
      navigator?.connection ||
      navigator?.mozConnection ||
      navigator?.webkitConnection ||
      {};
    const downlinkInMBps = (connection?.downlink / 8).toFixed(2);
    const errorObject = {
      message: error?.message || "UNKNOWN",
      errorLogs: error?.statusText || "UNKNOWN",
      connectionType: connection?.effectiveType || "",
      downlink: `${downlinkInMBps} Mbps`,
    };
    //SEND LOG
    EventEmitter(
      type,
      errorObject.message,
      errorObject.errorLogs,
      errorObject.connectionType,
      errorObject.downlink
    );
  }

  return (
    <>
      <Box className="row">
        <Box className="container">
          <Box
            className="dft tc"
            height="100vh"
            justifyContent="center"
            flexDirection="column"
            p="20px"
            gridGap="6px"
          >
            <Box
              className="dft"
              justifyContent="center"
              flexDirection="column"
              flex="1 0"
              gridGap="6px"
            >
              <svg
                height="32px"
                width="32px"
                viewBox="0 0 24 24"
                fill="var(--red)"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
              </svg>
              <Box className="bl f17 c1">Something went wrong!</Box>
              <Box className="f14 c2" lineHeight="1.2">
                We’re currently addressing an issue. Please{" "}
                <b>refresh the app</b> to continue using it smoothly.
              </Box>
              <Box
                pt="20px"
                style={{ transform: "scale(0.9)", transformOrigin: "top" }}
              >
                <Button cls="b2" onClick={tryAgain}>
                  Refresh Now
                </Button>
              </Box>
            </Box>
            <Box className="l1 f13 bl500 c2 o1">{slogan?.text}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ErrorBoundary;
