import { SET_LAST_SEARCH } from "./actionTypes";

const initialState = {
  lastSearch: null,
};
const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_SEARCH:
      return {
        ...state,
        lastSearch: {
          ...state.lastSearch,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default SearchReducer;
