import {
  SET_CHAT_LIST,
  SET_CHAT,
  ADD_UPDATE_MESSAGE,
  FIND_AND_REMOVE_MESSAGE,
  FIND_AND_UPDATE_MESSAGE,
} from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  chatList: {
    ...DEFAULT_DATA,
  },
  chat: {
    ...DEFAULT_DATA,
  },
};
const MessengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_LIST:
      return {
        ...state,
        chatList: {
          ...state.chatList,
          ...action.value,
        },
      };
    case SET_CHAT:
      return {
        ...state,
        chat: {
          ...state.chat,
          ...action.value,
        },
      };
    case ADD_UPDATE_MESSAGE: {
      const chatData = state?.chat?.payload?.data || [];
      const matchKey = action?.value?.clientMsgId ? "clientMsgId" : "_id";
      const findChat = chatData.find(
        (cData) => cData?.[matchKey] === action?.value?.[matchKey]
      );
      if (findChat) {
        // Update here
      } else {
        chatData.push(action.value);
      }
      return {
        ...state,
        chat: {
          ...state.chat,
          payload: {
            ...state?.chat?.payload,
            data: chatData,
          },
        },
      };
    }
    case FIND_AND_REMOVE_MESSAGE: {
      const chatData = state?.chat?.payload?.data || [];
      const matchKey = action?.value?.clientMsgId ? "clientMsgId" : "_id";
      const newChatList = chatData.filter(
        (cData) => cData?.[matchKey] !== action?.value?.[matchKey]
      );
      return {
        ...state,
        chat: {
          ...state.chat,
          payload: {
            ...state?.chat?.payload,
            data: newChatList,
          },
        },
      };
    }
    case FIND_AND_UPDATE_MESSAGE: {
      const chatData = state?.chat?.payload?.data || [];
      const matchKey = action?.value?.content ? "content" : "_id";
      const newChatList = chatData.map((cData) => {
        if (cData?.[matchKey] === action?.value?.[matchKey]) {
          return { ...cData, ...action?.value };
        }
        return cData;
      });

      return {
        ...state,
        chat: {
          ...state.chat,
          payload: {
            ...state?.chat?.payload,
            data: newChatList,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default MessengerReducer;
