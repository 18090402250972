import AppConfigReducer from "./appConfig/AppConfigReducer";
import DialogModalReducer from "../components/organisms/DialogModal/reducers";
import ToastReducer from "../components/organisms/Toast/reducers";
import SearchResultReducer from "../pages/SearchResult/reducer";
import SearchReducer from "../pages/Search/reducer";
import ProfileReducer from "../pages/Profile/reducer";
import ActivityCenterReducer from "../pages/ActivityCenterDashboard/reducer";
import HomeReducer from "../pages/Home/reducer";
import NotificationsReducer from "../pages/Notifications/reducer";
import MessengerReducer from "../pages/Messenger/reducer";
import ShortlistsReducer from "../pages/Shortlists/reducer";

const rootReducers = {
  appConfig: AppConfigReducer,
  modal: DialogModalReducer,
  toast: ToastReducer,
  searchResultPage: SearchResultReducer,
  searchPage: SearchReducer,
  profilePage: ProfileReducer,
  activityCenterPage: ActivityCenterReducer,
  homePage: HomeReducer,
  allNotification: NotificationsReducer,
  messenger: MessengerReducer,
  shortlists: ShortlistsReducer,
};

export default rootReducers;
