import Box from "../Box";

const Button = ({
  children,
  onClick,
  cls = "",
  attrValue,
  styles = {},
  loading,
  disabled,
  type = "button",
  ...props
}) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      data-action={attrValue}
      className={`btn-n f16 b1 cw ${cls}`}
      style={styles}
      disabled={disabled || loading}
      type={type}
      {...props}
    >
      {children}
      {loading && <BounceAnimation cls={cls} />}
    </Box>
  );
};

const BounceAnimation = ({ cls, size = "10px" }) => {
  return (
    <Box className={`bounceAnimation b1 ${cls}`}>
      <Box className="bounce1" color="#256FEF" height={size} width={size}></Box>
      <Box className="bounce2" color="#256FEF" height={size} width={size}></Box>
      <Box className="bounce3" color="#256FEF" height={size} width={size}></Box>
    </Box>
  );
};

export default Button;
