import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import AppRouter from "./routing/Routing";
import { setFirebaseScreenName } from "./helper/firebase";
import { ENVIRONMENTS } from "./constant/app.constant";

const Root = () => {
  useEffect(() => {
    // registerServiceWorker();
    AppRouter.subscribe((state) => {
      if (state?.historyAction === "PUSH") {
        try {
          setFirebaseScreenName(
            state?.location?.pathname?.substring(1).replace(/\//g, "$")
          );
        } catch (e) {
          console.log("error on setting firebase screen name", e);
        }
      }
    });
  }, []);

  function registerServiceWorker() {
    const isProduction = ENVIRONMENTS.REACT_APP_PRODUCTION;
    if (!isProduction) {
      return;
    }
    //Only for prod
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log(
              "Service Worker registered with scope:",
              registration.scope
            );
          })
          .catch((error) => {
            console.log("Service Worker registration failed:", error);
          });
      });
    }
  }

  return <RouterProvider router={AppRouter} />;
};

export default Root;
