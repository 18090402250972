import React, { Suspense, useCallback, useEffect, useLayoutEffect } from "react";
import "./App.scss";
import { createPortal } from "react-dom";
import "./services/httpInterceptor";
import useAuthorization from "./hooks/useAuthorization";
import { Outlet, useLocation } from "react-router-dom";
import { AppInitialization, EventEmitter, SendPageView } from "./helper/JumboEventEmitter";
import { useFcmMessage } from "./hooks/useFcmMessage";
import useRedirection from "./hooks/useRedirection";
import { watchDeeplink } from "./helper/nativehandler";
import { jsonObjToQueryParams } from "./helper/common";

/** Lazy */
const DialogModal = React.lazy(() =>
  import("./components/organisms/DialogModal")
);
const Toast = React.lazy(() => import("./components/organisms/Toast"));
const AppLoader = React.lazy(() => import("./components/atoms/AppLoader"));
/** END Lazy */

function App() {
  const location = useLocation();
  const { getAppConfig, getSecondaryConfig, updateDeviceInformation } =
    useAuthorization();
  const fcmMessage = useFcmMessage();
  const history = useRedirection();

  useLayoutEffect(() => {
    try {
      cordovaPluginInit();
    } catch (e) {
      const errorObject = {
        message: e?.message || "",
        errorLogs: e?.stack || "",
      };
      //SEND LOG
      EventEmitter('WEB_APP_CRASH', errorObject.message, errorObject.errorLogs);
    }

    updateDeviceInformation();
    //Get login user details
    getAppConfig();
    //initialize
    AppInitialization();
    getSecondaryConfig();
  }, []);
  
  useEffect(() => {
    SendPageView(`${location?.pathname}${location?.search || ""}`);
  }, [location]);

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // All native plugin initialize.
  const cordovaPluginInit = () => {
    // watch fcm message click
    fcmMessage.onFcmMessageTap(onFcmMessageTap);

    // watch deeplink click
    watchDeeplink((event) => {
      if (!event?.path) return;
      const queryString = jsonObjToQueryParams(event?.params);
      const route = `${event.path}${queryString ? '?'+queryString : ''}${event?.hash ? '#'+event.hash : ''}`;
      if (!route) return;
      history.navigate(route);
    });
  }

  const onFcmMessageTap = useCallback((fcmData) => {
    switch (fcmData.wType) {
      case 'ROUTE':
        if (!fcmData.wRoute) return;
        history.navigate(fcmData.wRoute);
        break;
    }
  }, []);

  return (
    <>
      <Outlet />
      <Suspense fallback={<></>}>
        <BodyPortal portal={<DialogModal />} />
        <BodyPortal portal={<Toast />} />
      </Suspense>
      {/* App Loader */}
      <BodyPortal portal={<AppLoader />} />
    </>
  );
}

const BodyPortal = ({ portal }) => {
  return createPortal(portal, document.body);
};

//Need to Lazy load
const lazyLoadCss = () => {
  import("swiper/css");
};

lazyLoadCss();

export default App;
