import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, COMMON_PATH } from "../constant/api.constant";
import {
  authentication,
  getPlatformData,
  loginSuccessfully,
  logoutSuccessfully,
  setPlatformData,
} from "../helper/authorization";
import { get, post } from "../helper/HttpService";
import {
  clearUserInfo,
  setCommonConfig,
  updateCountriesList,
} from "../redux/appConfig/actions";
import useDialog from "./useDialog";
import useRedirection from "./useRedirection";
import { checkIsMobileBrowser, isMobileDevice } from "../helper/common";
import { DEVICE_TYPE, ENVIRONMENTS } from "../constant/app.constant";
import { getDeviceInfo, isAndroid, isIos } from "../helper/nativehandler";
import { EventEmitter } from "../helper/JumboEventEmitter";

/** Lazy */
const LoginModal = React.lazy(() => import("../components/modals/Login"));
/** END - Lazy */

// Note: This is dump hooks Do not use useEffect
// For first time check all Authentication. this is main hooks
function useAuthorization() {
  const { openDialog, closeDialog } = useDialog();
  const dispatch = useDispatch();
  const { redirectToUrl } = useRedirection();
  const isMobile = isMobileDevice();
  const appConfig = useSelector((store) => store?.appConfig);

  function checkAuthentication(signUpPopup = true) {
    if (authentication()) {
      return true;
    }
    if (signUpPopup) {
      openSignInModal();
    }
    //Login
    return false;
  }

  function openSignInModal() {
    openDialog({
      componentToRender: LoginModal,
      isCustomHeader: false,
      styles: { width: "371px", minHeight: "200px" },
    });
  }

  function logout() {
    post(API_URL.LOGOUT).then((response) => {});
    setTimeout(() => {
      //Logout
      dispatch(clearUserInfo());
      logoutSuccessfully(redirectToUrl);
      EventEmitter("USER_LOGOUT");
    }, 200);
  }

  function loginSuccess(body, redirectURL, callback) {
    loginSuccessfully(body, redirectToUrl, redirectURL);
    //Emit Callback
    callback && callback(true);
  }

  function getCountryList() {
    if (appConfig?.countriesList?.length > 3) {
      return;
    }
    //Http
    get(API_URL.COUNTRY).then((response) => {
      if (response?.payload?.length) {
        dispatch(updateCountriesList(response.payload));
      }
    });
  }

  /**
   * @function getAppConfig
   * @summary Get app common config
   */
  function getAppConfig() {
    get(API_URL.APP_CONFIG)
      .then((response) => {
        dispatch(setCommonConfig(response.payload));
      })
      .catch((err) => {
        dispatch(setCommonConfig({ isApiFailed: true }));
      });
  }

  /**
   * @function getSecondaryConfig
   * @summary Get app common config
   */
  function getSecondaryConfig() {
    get(`${COMMON_PATH}secondary-config`).then((response) => {
      dispatch(setCommonConfig({ secondaryConfig: response.payload }));
    });
  }

  function updateDeviceInformation() {
    let deviceInfo;
    const getStoreData = getPlatformData();
    if (getStoreData) {
      deviceInfo = getStoreData;
    } else {
      let browser = DEVICE_TYPE.WEB;
      let version = ENVIRONMENTS.REACT_APP_VERSION;
      if (isAndroid()) {
        browser = DEVICE_TYPE.ANDROID;
        version = getDeviceInfo()?.version;
      } else if (isIos()) {
        browser = DEVICE_TYPE.IOS;
        version = getDeviceInfo()?.version;
      } else {
        if (checkIsMobileBrowser()) {
          browser = DEVICE_TYPE.MWEB;
          version = ENVIRONMENTS.REACT_APP_VERSION;
        }
      }
      deviceInfo = { browser: browser, version: version };
    }
    //Store
    window._deviceInfo = deviceInfo;
    setPlatformData(deviceInfo);
  }

  return {
    checkAuthentication,
    authentication: authentication(),
    getCountryList,
    userDetails: appConfig?.userDetails,
    userSettings: appConfig?.appConfig?.userSettings,
    isInternal: appConfig?.appConfig?.userSettings?.isInternal,
    settings: appConfig?.appConfig?.settings,
    appConfig,
    appConfigData: appConfig?.appConfig,
    secondaryConfig: appConfig?.appConfig?.secondaryConfig,
    loginSuccess,
    openDialog,
    closeDialog,
    logout,
    isMobile,
    dispatch,
    getAppConfig,
    getSecondaryConfig,
    updateDeviceInformation,
    getPlatformData,
  };
}
export default useAuthorization;
