import styled from "styled-components";
import Box from "../Box";

export const FlashingBox = styled(Box)`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--appSecondary);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;

  & .dot-flashing,
  & .dot-flashing::before,
  & .dot-flashing::after {
    content: "";
    height: 10px;
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    background-color: inherit;
    color: inherit;
    animation: dotFlashing 1s infinite alternate;
    border-radius: 5px;
  }

  & .dot-flashing::before {
    left: -15px;
    animation-delay: 0s;
  }

  & .dot-flashing::after {
    left: 15px;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--appSecondary);
    }
    50%,
    100% {
      background-color: #ffd2d8;
    }
  }
`;
