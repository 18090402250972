import { SET_SHORTLIST } from "./actionTypes";

const initialState = {
  shortlistData: {},
};
const ShortlistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHORTLIST:
      const activeTab = action?.value?.activeTab || "";
      return {
        ...state,
        shortlistData: {
          ...state.shortlistData,
          [activeTab]: {
            ...state.shortlistData[activeTab],
            ...action.value,
          },
        },
      };
    default:
      return state;
  }
};

export default ShortlistsReducer;
