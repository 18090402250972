import axios from "axios";
import { reqLimiter } from "./reqLimiter";

export function get(api, queryParams, headers = {}, options = {}) {
  return reqLimiter.schedule(() =>
    axios.get(api, {
      params: queryParams,
      headers: headers,
      ...options,
    })
  );
}

export function post(api, body, queryParams, headers = {}, options = {}) {
  return axios.post(api, body, {
    params: queryParams,
    headers,
    ...options,
  });
}

export function put(api, body, queryParams, headers = {}, options = {}) {
  return axios.put(api, body, {
    params: queryParams,
    headers,
    ...options,
  });
}

export function deleteReq(api, body, queryParams, headers = {}, options = {}) {
  return axios.delete(api, {
    data: body,
    params: queryParams,
    headers,
    ...options,
  });
}
