import React from "react";
import { createBrowserRouter } from "react-router-dom";
import CrashBoundary from "../components/organisms/ErrorBoundary";
import App from "../App";
import {
  ActivityCenterDashboardPage,
  AddPhotoPage,
  AllNotificationsPage,
  Dashboard,
  Home,
  LoginPage,
  MembershipPlanPage,
  NotificationsPage,
  ProfileDashboardPage,
  PreferredMatchesPage,
  ProfilePage,
  RegistrationNewPage,
  SearchResultPage,
  UserProfilePage,
  ViewProfilePage,
  SearchPage,
  MessengerPage,
  SettingsPage,
  MyConversationsPage,
  ChatPage,
  PageNotFound,
  DownloadPage,
  AccountAndSettingsPage,
  AllSettingsPage,
  PrivacySettingsPage,
  ManageFiltersPage,
  HideProfilePage,
  DeleteProfilePage,
  CompleteProfilePage,
  HelpSupportPage,
  CallbacksPage,
  ShortlistsPage,
} from "./LazyRoute";
import {
  ACCOUNT_AND_SETTINGS,
  ACTIVITY_CENTER,
  ADD_PHOTO,
  CALLBACK,
  DELETE_PROFILE,
  DOWNLOAD,
  HELP_AND_SUPPORT,
  HIDE_PROFILE,
  LOGIN,
  MANAGE_FILTERS,
  MEMBERSHIP_PLAN,
  MESSENGER,
  ME_PFOILE,
  NOTIFICATION,
  NOTIFICATION_VIEW_PROFILE,
  PAYMENT_MEMBERSHIP,
  PREFERRED_MATCHES,
  PRIVACY_SETTINGS,
  PROFILE_COMPLETE,
  REGISTER,
  SEARCH,
  SEARCH_RESULT,
  SETTINGS,
  SHORTLIST,
  USER_PROFILE,
} from "./routes.constant";
import MobileAppShimmer from "../pages/Dashboard/components/MobileAppShimmer";
import { isNative } from "../helper/nativehandler";

const ShowLoader = () => {
  const isNativeDevice = isNative();
  return isNativeDevice ? <MobileAppShimmer /> : <></>;
};

const AuthChildrenRoutes = [
  {
    path: "",
    element: (
      <React.Suspense fallback={<></>}>
        <Home />
      </React.Suspense>
    ),
  },
  {
    path: ME_PFOILE.path,
    element: (
      <React.Suspense fallback={<></>}>
        <ProfileDashboardPage />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: ADD_PHOTO.path,
        element: (
          <React.Suspense fallback={<></>}>
            <AddPhotoPage />
          </React.Suspense>
        ),
      },
      {
        path: PREFERRED_MATCHES.path,
        element: (
          <React.Suspense fallback={<></>}>
            <PreferredMatchesPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: `${USER_PROFILE.path}/:id`,
    element: (
      <React.Suspense fallback={<></>}>
        <UserProfilePage />
      </React.Suspense>
    ),
  },
  {
    path: SEARCH.path,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <SearchPage />
      </React.Suspense>
    ),
  },
  {
    path: `${SEARCH_RESULT.path}/:type?`,
    element: (
      <React.Suspense fallback={<></>}>
        <SearchResultPage />
      </React.Suspense>
    ),
  },
  {
    path: `${ACTIVITY_CENTER.path}/:type?`,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <ActivityCenterDashboardPage />
      </React.Suspense>
    ),
  },
  {
    path: NOTIFICATION.path,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <NotificationsPage />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <AllNotificationsPage />
          </React.Suspense>
        ),
      },
      {
        path: NOTIFICATION_VIEW_PROFILE.path,
        element: (
          <React.Suspense fallback={<></>}>
            <ViewProfilePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: MESSENGER.fullUrl,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <MessengerPage />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <MyConversationsPage />
          </React.Suspense>
        ),
      },
      {
        path: `${MESSENGER.fullUrl}/:chatId`,
        element: (
          <React.Suspense fallback={<></>}>
            <ChatPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SETTINGS.path,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <SettingsPage />
      </React.Suspense>
    ),
  },
  {
    path: ACCOUNT_AND_SETTINGS.path,
    element: (
      <React.Suspense fallback={<></>}>
        <AccountAndSettingsPage />
      </React.Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <React.Suspense fallback={<></>}>
            <AllSettingsPage />
          </React.Suspense>
        ),
      },
      {
        path: PRIVACY_SETTINGS.path,
        element: (
          <React.Suspense fallback={<></>}>
            <PrivacySettingsPage />
          </React.Suspense>
        ),
      },
      {
        path: MANAGE_FILTERS.path,
        element: (
          <React.Suspense fallback={<></>}>
            <ManageFiltersPage />
          </React.Suspense>
        ),
      },
      {
        path: HIDE_PROFILE.path,
        element: (
          <React.Suspense fallback={<></>}>
            <HideProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: DELETE_PROFILE.path,
        element: (
          <React.Suspense fallback={<></>}>
            <DeleteProfilePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: PAYMENT_MEMBERSHIP.fullUrl,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <MembershipPlanPage />
      </React.Suspense>
    ),
  },
  {
    path: PROFILE_COMPLETE.path,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <CompleteProfilePage />
      </React.Suspense>
    ),
  },
  {
    path: `${HELP_AND_SUPPORT.fullUrl}/*`,
    element: (
      <React.Suspense fallback={<></>}>
        <HelpSupportPage />
      </React.Suspense>
    ),
  },
  {
    path: `${SHORTLIST.fullUrl}/*`,
    element: (
      <React.Suspense fallback={<ShowLoader />}>
        <ShortlistsPage />
      </React.Suspense>
    ),
  },
];

const ChildrenRoutes = [
  {
    path: "",
    element: (
      <React.Suspense fallback={<></>}>
        <Dashboard />
      </React.Suspense>
    ),
    children: [...AuthChildrenRoutes],
  },
  {
    path: LOGIN.path,
    element: (
      <React.Suspense fallback={<></>}>
        <LoginPage />
      </React.Suspense>
    ),
  },
  {
    path: REGISTER.path,
    element: (
      <React.Suspense fallback={<></>}>
        <RegistrationNewPage />
      </React.Suspense>
    ),
  },
  {
    path: MEMBERSHIP_PLAN.fullUrl,
    element: (
      <React.Suspense fallback={<></>}>
        <MembershipPlanPage />
      </React.Suspense>
    ),
  },
  {
    path: `${CALLBACK.path}/*`,
    element: (
      <React.Suspense fallback={<></>}>
        <CallbacksPage />
      </React.Suspense>
    ),
  },
  {
    path: DOWNLOAD.fullUrl,
    element: (
      <React.Suspense fallback={<></>}>
        <DownloadPage />
      </React.Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <React.Suspense fallback={<></>}>
        <PageNotFound />
      </React.Suspense>
    ),
  },
];

const AppRouter = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement: <CrashBoundary />,
    children: [...ChildrenRoutes],
  },
]);

export default AppRouter;
