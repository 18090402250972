import { API_URL } from "../constant/api.constant";
import { ENVIRONMENTS } from "../constant/app.constant";
import { post } from "./HttpService";
import { getAppInitialize, setAppInitialize } from "./authorization";
import { cleanData, debounce } from "./common";
let ReactGA;

/**************************************************************************************************************
 *                      START : SEND LOGS
 ***************************************************************************************************************/

//Logs Error
let logsStore = [];
const sendLogs = debounce(sendAvailableLogs, 2000);
const isProduction = ENVIRONMENTS.REACT_APP_PRODUCTION;

if (isProduction) {
  import("react-ga").then((module) => {
    ReactGA = module.default;
    ReactGA.initialize(ENVIRONMENTS.REACT_APP_G_ANALYTICS_ID, {
      titleCase: "app-initialize",
    });
  });
}

export const EventEmitter = (eventName, ...logs) => {
  //Local or other Env.
  if (!isProduction) {
    return;
  }
  //Send Event
  logsStore.push({ ...getLogsPreFixData(eventName, ...logs) });
  //Send Event
  sendLogs();
};

export function sendAvailableLogs() {
  //**Logs not Available*/
  if (!logsStore?.length) {
    logsStore = [];
    return;
  }
  //**Send logs to Http*/
  post(API_URL.JUMBO_LOG, logsStore)
    .then((response) => {
      //
    })
    .catch((error) => {
      //
    })
    .finally(() => {
      logsStore = [];
    });
}

/**************************************************************************************************************
 *                      END : SEND LOGS
 ***************************************************************************************************************/

export function getLogsPreFixData(type, var1, var2, var3, var4, var5, var6) {
  const data = {
    event: type,
    url: window.location.href,
    var1,
    var2,
    var3,
    var4,
    var5,
    var6,
  };
  //Check as a string or not
  for (const key in data) {
    const value = data[key];
    if (data.hasOwnProperty(key) && typeof value !== "string") {
      data[key] = JSON.stringify(value);
    }
  }

  return cleanData(data);
}

export function SentAnalyticsEvent({ category, action, label }, trackerNames) {
  if (isProduction && ReactGA) {
    ReactGA.event({ category, action, label }, trackerNames);
  }
}

export function AppInitialization() {
  if (isProduction && ReactGA) {
    ReactGA.initialize(ENVIRONMENTS.REACT_APP_G_ANALYTICS_ID, {
      titleCase: "app-initialize",
    });
    const platformInfo = getAppInitialize();
    if (!platformInfo) {
      let varInfo = [];
      if (window?.navigator?.connection) {
        varInfo = [
          window?.navigator?.connection?.effectiveType,
          window?.navigator?.connection?.downlink,
        ];
      }
      //sent
      EventEmitter("APP_INITIALIZE", ...varInfo);
    }
  }
  //Set App Initialize
  setAppInitialize();
}

export function SendPageView(path) {
  if (isProduction && ReactGA) {
    ReactGA.pageview(path);
  }
}
