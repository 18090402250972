import { SET_NOTIFICATION, SET_WHO_PROFILE_VIEW } from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  whoViewProfile: {
    ...DEFAULT_DATA,
  },
  notifications: {
    ...DEFAULT_DATA,
  },
};
const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WHO_PROFILE_VIEW:
      return {
        ...state,
        whoViewProfile: {
          ...state.whoViewProfile,
          ...action.value,
        },
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

export default NotificationReducer;
