import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

export const PUBLISHED_ART = "dd. MMM y";
export const FULL_MONTH_YEAR = "MMMM y";

export const dateTimeFormat = (date, type = PUBLISHED_ART) => {
  try {
    return format(new Date(date), type);
  } catch (error) {
    return "";
  }
};

export const lastSeen = (date, type = PUBLISHED_ART) => {
  try {
    return format(new Date(date), type);
  } catch (error) {
    return "";
  }
};

export const formatDistanceToNow = (date, type) => {
  try {
    return formatDistanceToNowStrict(date);
  } catch (error) {
    return null;
  }
};

export const formatDistanceLastSeen = (date) => {
  if (!date) {
    return null;
  }
  try {
    const currentDate = new Date();
    const lastSeenDate = new Date(date);

    const isToday =
      currentDate.getFullYear() === lastSeenDate.getFullYear() &&
      currentDate.getMonth() === lastSeenDate.getMonth() &&
      currentDate.getDate() === lastSeenDate.getDate();

    if (isToday) {
      const hours = lastSeenDate.getHours();
      const minutes = lastSeenDate.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `Last Seen at ${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      return `Last Seen on ${dateTimeFormat(lastSeenDate, "dd-MMM-y")}`;
    }
  } catch (_) {
    return null;
  }
};

export const setFormatDDMMYYYYtoMMDDYYYY = (date, separator = "/") => {
  if (!date) {
    return null;
  }

  try {
    const [year, month, day] = date.split("-");
    return month + separator + day + separator + year;
  } catch (error) {
    return null;
  }
};

export function calculateAgeDifference(birthDate1) {
  const date1 = new Date(birthDate1);
  const date2 = new Date();

  const diffInMilliseconds = Math.abs(date2 - date1);
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;

  const ageDifference = Math.floor(diffInMilliseconds / millisecondsInYear);

  return ageDifference;
}

export function calculateAge(dateOfBirth) {
  try {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  } catch (_) {
    return "";
  }
}
