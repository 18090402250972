import { callIfFunction } from "./common";

export const isAndroid = () => {
  return window.cordova?.platformId === "android";
};

export const isIos = () => {
  return window.cordova?.platformId === "ios";
};

export const isWeb = () => {
  return typeof window.cordova?.platformId === "undefined";
};

export const getDeviceInfo = () => {
  if (window?.getDeviceInfo) {
    return { ...window?.getDeviceInfo() };
  }
  //For our first android build issue getDeviceInfo not found
  return { version: "1.16.3" };
};

export const isNative = () => {
  return isAndroid() || isIos();
};

export const getCordovaPlugin = (pluginName) => {
  return window.cordova?.plugins?.[pluginName];
};

export const pullToRefreshInit = () => {
  import("pulltorefreshjs").then((pullToRefresh) => {
    pullToRefresh.init({
      mainElement: "body",
      onRefresh: () => {
        window.location.reload();
      },
    });
  });
};

export const getFirebasePlugin = () => {
  return window.FirebasePlugin;
};

export const hasPushNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    if (!getFirebasePlugin()) {
      reject("FirebasePlugin not found!");
      return;
    }

    getFirebasePlugin().hasPermission(function (hasPermission) {
      resolve(hasPermission);
    });
  });
};

export const askPushNotificationPermission = () => {
  return new Promise(async (resolve, reject) => {
    if (!getFirebasePlugin()) {
      reject("FirebasePlugin not found!");
      return;
    }

    if (await hasPushNotificationPermission()) {
      resolve(true);
      return;
    }

    getFirebasePlugin().grantPermission(function (hasPermission) {
      hasPermission ? resolve(true) : reject();
    });
  });
};

export const getFirebaseDeviceToken = () => {
  return new Promise((resolve, reject) => {
    if (!getFirebasePlugin()) {
      reject("FirebasePlugin not found!");
      return;
    }

    getFirebasePlugin().getToken((token) => {
      token ? resolve(token) : reject();
    });
  });
};

export const setupPushNotification = async () => {
  await askPushNotificationPermission();
  return await getFirebaseDeviceToken();
};

export const openExternalBrowser = (url) => {
  window.cordova?.InAppBrowser.open(
    `googlechrome://navigate?url=${url}`,
    "_system",
    ""
  );
};

export const watchDeeplink = (callback) => {
  if (typeof window.universalLinks === 'undefined') return;

  console.log('deeplink watching');
  window.universalLinks.subscribe('PahadiRishtaDeeplink', callback);
}

export const NativeSms = {
  send: function (mobileNumber, smsContent, onSuccess, onError) {
    if (typeof window.sms === 'undefined') {
      callIfFunction(onError, "APP_UPDATE");
      return
    };

    var options = {
      replaceLineBreaks: true,
      android: {
        intent: 'INTENT'
      }
    };

    window.sms?.send(mobileNumber, smsContent, options, () => {
      callIfFunction(onSuccess);
    }, () => {
      callIfFunction(onError);
    });
  }
};
