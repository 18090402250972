const BASE_PATH = "app/config/common/";

export const UPDATE_LOGIN_USER_INFO = `${BASE_PATH}set-login-user-info`;
export const UPDATE_COUNTRIES_LIST = `${BASE_PATH}set-countries-list`;
export const CLEAR_USER_INFO = `${BASE_PATH}clear-user-info`;
export const TOGGLE_APP_LOADER = `${BASE_PATH}toggle-app-loader`;
export const SUBSCRIPTION_PLAN = `${BASE_PATH}subscription-plan`;
export const COMMON_CONFIG = `${BASE_PATH}set-app-config`;
export const UPDATE_COMMON_CONFIG = `${BASE_PATH}set-update-app-config`;
export const OCCUPATION_LIST = `${BASE_PATH}set-occupation-list`;
export const DEGREE_LIST = `${BASE_PATH}set-degree-list`;
export const UPDATE_SINGLE_PROFILE = `${BASE_PATH}update-single-profile`;
export const UPDATE_SECONDARY_CONFIG = `${BASE_PATH}update-secondary-config`;
