import { HIDE_TOAST, SHOW_TOAST } from "./actions";

const initialState = {
  isShow: false,
};

export default function ToastReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return { ...state, ...action.value, isShow: true };
    case HIDE_TOAST:
      return { isShow: false };
    default:
      return state;
  }
}
