import { getFirebasePlugin } from "./nativehandler";

let isFcmMessageAlreadyListen = false;

export const initFirebase = (userId) => {
    const firebasePlugin = getFirebasePlugin();

    if (!firebasePlugin) return;

    if (userId) {
        setUserId(userId);
    }
}

const setUserId = (userId) => {
    const firebasePlugin = getFirebasePlugin();

    if (!firebasePlugin) return;

    firebasePlugin.setUserId(userId);
}

export const setFirebaseUserProperties = (userProps) => {
    const firebasePlugin = getFirebasePlugin();

    if (!firebasePlugin) return;

    firebasePlugin.setUserProperty("name", userProps.firstName);
    firebasePlugin.setUserProperty("email", userProps.email);
    firebasePlugin.setUserProperty("createdAt", userProps.createdAt);
    firebasePlugin.setUserProperty("gender", userProps.gender);
    firebasePlugin.setUserProperty("phoneNo", userProps.phoneNo);
    firebasePlugin.setUserProperty("username", userProps.username);
};

export const listenFcmMessage = (onTap, onReceived) => {
    const firebasePlugin = getFirebasePlugin();

    if (!firebasePlugin) return;

    if (isFcmMessageAlreadyListen) {
        console.error("FCM message already listen, please use useFcm hook");
    }

    console.log('fcm message listen');
    isFcmMessageAlreadyListen = true;
    firebasePlugin.onMessageReceived((message) => {
        if (typeof onReceived === 'function') {
            onReceived(message);
        }

        console.log("fcm message receive ", message);
        if (message.tap) {
            if (typeof onTap === 'function') {
                console.log("fcm message onTap ", message);
                onTap(message);
            }
        }
    });
}

export const logFirebaseEvent = (event, props = {}) => {
    const firebasePlugin = getFirebasePlugin();

    if (!firebasePlugin) return;

    firebasePlugin.logEvent(event, {
        ...props,
    });
}

export const setFirebaseScreenName = (screenName) => {
    const firebasePlugin = getFirebasePlugin();

    if (!firebasePlugin || !screenName) return;

    firebasePlugin.setScreenName(screenName);
}
