import React from "react";
import Box from "../Box";
import { FlashingBox } from "./style";

const FlashingLoader = ({ mt = "0px" }) => {
  return (
    <FlashingBox mt={mt}>
      <Box className="dot-flashing"></Box>
    </FlashingBox>
  );
};

export default FlashingLoader;
