import { CLOSE_MODAL, OPEN_MODAL } from "./actions";

const DEFAULT_STATE = {
  isOpen: false,
  isCustomHeader: true,
  isOutsideClickClose: true,
};

const initialState = { ...DEFAULT_STATE };

export default function DialogModalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, ...action.value, isOpen: true };
    case CLOSE_MODAL:
      return { ...DEFAULT_STATE };
    default:
      return state;
  }
}
