import {
  CLEAR_USER_INFO,
  COMMON_CONFIG,
  DEGREE_LIST,
  OCCUPATION_LIST,
  SUBSCRIPTION_PLAN,
  TOGGLE_APP_LOADER,
  UPDATE_COMMON_CONFIG,
  UPDATE_COUNTRIES_LIST,
  UPDATE_LOGIN_USER_INFO,
  UPDATE_SECONDARY_CONFIG,
  UPDATE_SINGLE_PROFILE,
} from "./appConfig.constants";

export const updateLoginUserInfo = (body) => ({
  type: UPDATE_LOGIN_USER_INFO,
  value: { ...body },
});
export const setCommonConfig = (body) => ({
  type: COMMON_CONFIG,
  value: { ...body },
});
export const updateCommonConfig = (body) => ({
  type: UPDATE_COMMON_CONFIG,
  value: { ...body },
});

export const clearUserInfo = (body = null) => ({
  type: CLEAR_USER_INFO,
  value: body,
});
export const updateOccupationList = (body) => ({
  type: OCCUPATION_LIST,
  value: body,
});
export const updateDegreeList = (body) => ({
  type: DEGREE_LIST,
  value: body,
});

export const toggleAppLoader = (isLoader) => ({
  type: TOGGLE_APP_LOADER,
  value: isLoader,
});

export const subscriptionPlan = (body) => ({
  type: SUBSCRIPTION_PLAN,
  value: body,
});

export const updateCountriesList = (body) => ({
  type: UPDATE_COUNTRIES_LIST,
  value: body,
});

export const updateSingleProfile = (body) => ({
  type: UPDATE_SINGLE_PROFILE,
  value: body,
});

export const updateSecondaryConfig = (body) => ({
  type: UPDATE_SECONDARY_CONFIG,
  value: body,
});
