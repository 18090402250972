import { token } from "../../../helper/authorization";

export const getAuthorizationHeaders = () => {
  //Check Token
  const tokenString = token();

  const auth = {
    "content-type": "application/json",
    "x-platform-code": window?._deviceInfo?.browser || "WEB",
    "x-platform-version": window?._deviceInfo?.version || "Unknown",
  };
  //Append Token
  if (tokenString) {
    auth["Authorization"] = `Bearer ${tokenString}`;
  }
  return auth;
};
