export const HOME = {
  path: "",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const ACTIVITY_CENTER = {
  path: "activity-center",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const SEARCH = {
  path: "search",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const SEARCH_RESULT = {
  path: "browse/search",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const NOTIFICATION = {
  path: "notification",
  get fullUrl() {
    return `/${this.path}`;
  },
};
export const NOTIFICATION_VIEW_PROFILE = {
  path: "view-profile",
  get fullUrl() {
    return `${NOTIFICATION.fullUrl}/${this.path}`;
  },
};

export const LOGIN = {
  path: "login",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const REGISTER = {
  path: "register",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const CALLBACK = {
  path: "callback",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const MEMBERSHIP_PLAN = {
  path: "membership-plan",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const PAYMENT_MEMBERSHIP = {
  path: "paywall",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const USER_PROFILE = {
  path: "p",
  get fullUrl() {
    return `/p`;
  },
};

export const ME_PFOILE = {
  path: "me",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const ADD_PHOTO = {
  path: "add-photo",
  get fullUrl() {
    return `${ME_PFOILE.fullUrl}/${this.path}`;
  },
};
export const PREFERRED_MATCHES = {
  path: "preferred-matches",
  get fullUrl() {
    return `${ME_PFOILE.fullUrl}/${this.path}`;
  },
};

export const MESSENGER = {
  path: "messenger",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const SETTINGS = {
  path: "settings",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const DOWNLOAD = {
  path: "download",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const ACCOUNT_AND_SETTINGS = {
  path: "account-settings",
  get fullUrl() {
    return `/${this.path}`;
  },
};
export const PRIVACY_SETTINGS = {
  path: "privacy-settings",
  get fullUrl() {
    return `${ACCOUNT_AND_SETTINGS.fullUrl}/${this.path}`;
  },
};
export const MANAGE_FILTERS = {
  path: "manage-filters",
  get fullUrl() {
    return `${ACCOUNT_AND_SETTINGS.fullUrl}/${this.path}`;
  },
};
export const HIDE_PROFILE = {
  path: "hide-profile",
  get fullUrl() {
    return `${ACCOUNT_AND_SETTINGS.fullUrl}/${this.path}`;
  },
};
export const DELETE_PROFILE = {
  path: "delete-profile",
  get fullUrl() {
    return `${ACCOUNT_AND_SETTINGS.fullUrl}/${this.path}`;
  },
};
export const PROFILE_COMPLETE = {
  path: "profile-complete",
  get fullUrl() {
    return `/${this.path}`;
  },
};

export const HELP_AND_SUPPORT = {
  path: "help-support",
  get fullUrl() {
    return `/${this.path}`;
  },
};
export const SHARE_FEEDBACK = {
  path: "share-feedback",
  get fullUrl() {
    return `${HELP_AND_SUPPORT.fullUrl}/${this.path}`;
  },
};

export const SHORTLIST = {
  path: "shortlist",
  get fullUrl() {
    return `/${this.path}`;
  },
};