import { UPDATE_SINGLE_PROFILE } from "../../redux/appConfig/appConfig.constants";
import {
  CLEAR_SEARCH_RESULT,
  SET_SEARCH,
  UPDATE_SCROLL_HEIGHT,
  UPDATE_SEARCH_FILTER,
} from "./actionTypes";

const DEFAULT_DATA = {
  isApiCallInProgress: true,
  payload: null,
};
const initialState = {
  searchResult: {
    ...DEFAULT_DATA,
  },
  lastScrollHeight: 0,
  lastFilterData: {},
};
const SearchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          ...action.value,
        },
      };
    case UPDATE_SCROLL_HEIGHT:
      return {
        ...state,
        lastScrollHeight: action.value,
      };
    case CLEAR_SEARCH_RESULT:
      return {
        ...state,
        searchResult: {
          ...DEFAULT_DATA,
        },
        lastScrollHeight: 0,
      };
    case UPDATE_SINGLE_PROFILE:
      const updatePayload = findAndUpdate(
        action?.value?.updateId,
        state?.searchResult,
        action?.value
      );
      return {
        ...state,
        searchResult: {
          ...updatePayload,
        },
      };
    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        lastFilterData: {
          ...state.lastFilterData,
          ...action.value,
        },
      };
    default:
      return state;
  }
};

function findAndUpdate(_id, data, updateData) {
  if (data?.payload?.data?.length) {
    const findData = (data?.payload?.data || []).map((item) => {
      if (item._id === _id) {
        return { ...item, ...updateData };
      }
      return item;
    });
    //update object
    data.payload.data = findData;
  }
  return JSON.parse(JSON.stringify(data));
}

export default SearchResultReducer;
