import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { listenFcmMessage } from "../helper/firebase";

const FcmMessageContext = createContext('fcmMessage');

export const FcmMessageProvider = ({ children }) => {
    const [value, setValue] = useState({});

    useLayoutEffect(() => {
        listenFcmMessage((messageData) => {
            setValue(messageData);
        });
    }, []);

    return (
        <FcmMessageContext.Provider value={value}>
            {children}
        </FcmMessageContext.Provider>
    )
}

export default FcmMessageContext;
