import React, { memo, useMemo } from "react";
import Box from "../../../../components/atoms/Box";
import FlashingLoader from "../../../../components/atoms/FlashingLoader";
import { APP_LOADING_TEXT } from "../../../../constant/app.constant";

const MobileAppShimmer = memo(() => {
  const slogan = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * APP_LOADING_TEXT.length);
    return APP_LOADING_TEXT[randomIndex];
  }, []);

  return (
    <Box
      className="row dft"
      bg="#f6f7fa"
      justifyContent="center"
      height="100vh"
      overflow="hidden"
    >
      <Box px="20px">
        {slogan?.text && (
          <Box as="p" pb="20px" className="c1 o2 nomg tc f18 bl500">
            {slogan?.text || ""}
          </Box>
        )}
        <FlashingLoader />
      </Box>
    </Box>
  );
});

export default MobileAppShimmer;
