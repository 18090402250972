export const OPEN_MODAL = "app_open_modal";
export const CLOSE_MODAL = "app_close_modal";

export const openModal = (data) => {
  return {
    type: OPEN_MODAL,
    value: data,
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};
