import axios from "axios";
import { logoutSuccessfully } from "../../helper/authorization";
import { getAuthorizationHeaders } from "./auth/authService";
import { EventEmitter } from "../../helper/JumboEventEmitter";

//Default timeout
axios.defaults.timeout = 15000;
//Interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      ...getAuthorizationHeaders(),
    };
    //Return
    return config;
  },
  //Error
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    //Return
    return response?.data;
  },
  function (error) {
    errorState(error);
    //Return
    return Promise.reject(getErrorData(error));
  }
);

const getErrorData = (err) => {
  return err?.response?.data || err;
};

/*------Error handling-------*/
function errorState(error) {
  const errorData = {
    message: error?.response?.data?.message || error?.message,
    errorLogs: error?.config?.url,
    statusCode: error?.request?.status,
  };
  const sentLogData = [
    errorData.errorLogs,
    errorData.message,
    errorData.statusCode,
  ];
  if (error?.code === "ERR_NETWORK") {
    //Network error
    EventEmitter("NETWORK_ERROR", ...sentLogData);
  } else if (error?.code === "ECONNABORTED") {
    //Api Timeout
    EventEmitter("NETWORK_ERROR", ...sentLogData);
  } else if (error?.request?.status === 401) {
    logoutSuccessfully();
    EventEmitter("UNAUTHORIZED_LOGOUT", ...sentLogData);
  } else if (error?.request?.status === 500) {
    EventEmitter("INTERNAL_SERVER_ERROR", ...sentLogData);
  }
}
