import { ENVIRONMENTS } from "./app.constant";

const BASE_PATH = ENVIRONMENTS.BASE_PATH;
export const MIDDLE_PATH = `${BASE_PATH}user/`;
export const COMMON_PATH = `${BASE_PATH}common/`;

export const API_URL = {
  COUNTRY: `${COMMON_PATH}country`,
  STATE: `${COMMON_PATH}state`,
  CITY: `${COMMON_PATH}city`,
  REGISTER: `${MIDDLE_PATH}signup`,
  LOGIN: `${MIDDLE_PATH}login`,
  FORGOT: `${MIDDLE_PATH}forgot-password`,
  RESET_PASSWORD: `${MIDDLE_PATH}reset-password`,
  LOGOUT: `${MIDDLE_PATH}logout`,
  MY_INFO: `${MIDDLE_PATH}me`,
  MY_SHORT_INFO: `${MIDDLE_PATH}me/short`,
  ADD_PHOTO: `${MIDDLE_PATH}add-photo`,
  DELETE_PHOTO: `${MIDDLE_PATH}delete-photo`,
  UPDATE_PHOTO: `${MIDDLE_PATH}update-photo`,
  SEARCH: `${COMMON_PATH}search`,
  PROFILE: `${MIDDLE_PATH}profile`,
  PROFILE_PRIVATE_DATA: `${MIDDLE_PATH}get-private-data`,
  SUBSCRIPTION: `${COMMON_PATH}get-subscription`,
  APP_CONFIG: `${COMMON_PATH}config`,
  MY_ACTIVITY: `${MIDDLE_PATH}get-interest`,
  EXPRESS_INTREST: `${MIDDLE_PATH}interest`,
  SHORTLIST: `${MIDDLE_PATH}shortlist`,
  BLOCK_USER: `${MIDDLE_PATH}ignore`,
  REPORT_PROFILE: `${MIDDLE_PATH}report`,
  OCCUPATION_LIST: `${COMMON_PATH}occupation-list`,
  DEGREE_LIST: `${COMMON_PATH}degree-list`,
  OTHER_PROFILE_VISIT: `${MIDDLE_PATH}me-to-other-profile-visit`,
  WHO_VIEW_MY_PROFILE: `${MIDDLE_PATH}other-to-me-profile-visit`,
  SENT_OTP: `${MIDDLE_PATH}sent-otp`,
  VERIFY_TOKEN: `${MIDDLE_PATH}verify-otp`,
  CHAT_LIST: `${MIDDLE_PATH}chatList`,
  CHAT: `${MIDDLE_PATH}chat`,
  SEND_MESSAGE: `${MIDDLE_PATH}send-message`,
  REQUEST_CALLBACK: `${COMMON_PATH}request-callback`,
  NOTIFICATION: `${MIDDLE_PATH}notifications`,
  SEND_PHOTO_REQUEST: `${MIDDLE_PATH}photo-request`,
  ADD_PUSH_NOTIFICATION_TOKEN: `${MIDDLE_PATH}fcm/add`,
  PRIVACY_SETTINGS: `${MIDDLE_PATH}privacy/get`,
  PRIVACY_SETTINGS_UPDATE: `${MIDDLE_PATH}privacy/update`,
  HIDE_PROFILE: `${MIDDLE_PATH}privacy/hide-profile`,
  DELETE_PROFILE: `${MIDDLE_PATH}privacy/delete-profile`,
  JUMBO_LOG: `${COMMON_PATH}jumbo`,
  UNSEEN_COUNT: `${MIDDLE_PATH}unseenCount`,
};
