export const SHOW_TOAST = "show_app_toast";
export const HIDE_TOAST = "hide_app_toast";

export const showToast = (data) => {
  return {
    type: SHOW_TOAST,
    value: data,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST,
  };
};
