import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  closeModal,
  openModal,
} from "../components/organisms/DialogModal/actions";

export default function useDialog() {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const openModalHandler = useCallback(
    (...args) => {
      dispatch(openModal(...args));
      toggleModalCssClass(true);
    },
    [dispatch]
  );

  const closeModalHandler = useCallback(
    (...args) => {
      dispatch(closeModal(...args));
      toggleModalCssClass(false);
    },
    [dispatch]
  );

  function toggleModalCssClass(isAddClass = true) {
    if (isAddClass) {
      document.body.classList.add("open-app-modal");
    } else {
      document.body.classList.remove("open-app-modal");
    }
  }

  return {
    modalState,
    openDialog: openModalHandler,
    closeDialog: closeModalHandler,
    dispatch,
  };
}
