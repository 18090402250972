import React from "react";

/** Lazy */
export const Dashboard = React.lazy(() => import("../pages/Dashboard"));

export const Home = React.lazy(() => import("../pages/Home"));

export const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));

export const CallbacksPage = React.lazy(() =>
  import("../pages/Callbacks")
);

//Post Routes

export const LoginPage = React.lazy(() => import("../pages/LoginPage"));

export const RegistrationNewPage = React.lazy(() =>
  import("../pages/RegistrationNew")
);

export const ProfileDashboardPage = React.lazy(() =>
  import("../pages/ProfileDashboard")
);
export const ProfilePage = React.lazy(() =>
  import("../pages/ProfileDashboard/Pages/Profile")
);
export const AddPhotoPage = React.lazy(() =>
  import("../pages/ProfileDashboard/Pages/AddPhotos")
);
export const PreferredMatchesPage = React.lazy(() =>
  import("../pages/ProfileDashboard/Pages/PreferredMatches")
);

export const UserProfilePage = React.lazy(() => import("../pages/Profile"));
export const SearchResultPage = React.lazy(() =>
  import("../pages/SearchResult")
);
export const SearchPage = React.lazy(() => import("../pages/Search"));
export const ActivityCenterDashboardPage = React.lazy(() =>
  import("../pages/ActivityCenterDashboard")
);

export const NotificationsPage = React.lazy(() =>
  import("../pages/Notifications")
);
export const AllNotificationsPage = React.lazy(() =>
  import("../pages/Notifications/Pages/AllNotifications")
);
export const ViewProfilePage = React.lazy(() =>
  import("../pages/Notifications/Pages/ViewProfile")
);

export const MembershipPlanPage = React.lazy(() =>
  import("../pages/MembershipPlan")
);

export const MessengerPage = React.lazy(() => import("../pages/Messenger"));

export const MyConversationsPage = React.lazy(() =>
  import("../pages/Messenger/Pages/MyConversations")
);

export const ChatPage = React.lazy(() =>
  import("../pages/Messenger/Pages/Chat")
);

export const SettingsPage = React.lazy(() => import("../pages/Settings"));
export const AccountAndSettingsPage = React.lazy(() =>
  import("../pages/AccountAndSettings")
);
export const AllSettingsPage = React.lazy(() =>
  import("../pages/AccountAndSettings/Pages/AllSettings")
);
export const PrivacySettingsPage = React.lazy(() =>
  import("../pages/AccountAndSettings/Pages/PrivacySettings")
);
export const ManageFiltersPage = React.lazy(() =>
  import("../pages/AccountAndSettings/Pages/ManageFilters")
);
export const HideProfilePage = React.lazy(() =>
  import("../pages/AccountAndSettings/Pages/HideProfile")
);
export const DeleteProfilePage = React.lazy(() =>
  import("../pages/AccountAndSettings/Pages/DeleteProfile")
);

export const CompleteProfilePage = React.lazy(() =>
  import("../pages/CompleteProfile")
);

export const DownloadPage = React.lazy(() => import("../pages/Download"));
export const HelpSupportPage = React.lazy(() => import("../pages/HelpSupport"));
export const ShortlistsPage = React.lazy(() => import("../pages/Shortlists"));
/** END - Lazy */
