import { UPDATE_SINGLE_PROFILE } from "../../redux/appConfig/appConfig.constants";
import { SET_DATA } from "./actionTypes";

const initialState = {};

const ActivityCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      const id = action.value.id;
      return {
        ...state,
        [id]: {
          ...(state?.[id] || {}),
          ...action.value.body,
        },
      };
    case UPDATE_SINGLE_PROFILE:
      let newState = JSON.parse(JSON.stringify(state || {}));
      for (const key in newState) {
        const stateValue = newState[key];
        if (stateValue?.data?.length) {
          stateValue.data = findAndUpdate(
            action?.value?.updateId,
            stateValue?.data,
            action?.value
          );
          newState[key] = stateValue;
        }
      }
      return newState;
    default:
      return state;
  }
};
//Find and update
function findAndUpdate(_id, data, updateData) {
  if (data?.length) {
    const findData = (data || []).map((item) => {
      if (item._id === _id) {
        return { ...item, ...updateData };
      }
      return item;
    });
    //update object
    data = findData;
  }
  return JSON.parse(JSON.stringify(data));
}

export default ActivityCenterReducer;
