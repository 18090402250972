import { UPDATE_SINGLE_PROFILE } from "../../redux/appConfig/appConfig.constants";
import { SET_PROFILE, UPDATE_PROFILE } from "./actionTypes";

const initialState = {
  profiles: {},
};
const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE: {
      const username = action?.value.username;
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [username]: {
            ...state.profiles?.[username],
            ...action.value,
          },
        },
      };
    }
    case UPDATE_PROFILE: {
      const username = action?.value.username;
      return {
        ...state,
        profiles: addAndUpdate(username, state?.profiles, action?.value),
      };
    }
    case UPDATE_SINGLE_PROFILE:
      const updatePayload = findAndUpdate(
        action?.value?.updateId,
        state?.profiles,
        action?.value
      );
      return {
        ...state,
        profiles: updatePayload,
      };
    default:
      return state;
  }
};

function findAndUpdate(_id, data, updateData) {
  const size = Object.keys(data || {})?.length;
  if (size) {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        let currentElement = data[key];
        if (currentElement?.payload?._id === _id) {
          data[key].payload = { ...currentElement.payload, ...updateData };
        }
      }
    }
  }
  return JSON.parse(JSON.stringify(data));
}

function addAndUpdate(username, data, updateData) {
  if (!data?.[username]) {
    data[username] = updateData;
  } else {
    if (data?.[username]?.payload) {
      data[username].payload = {
        ...data[username].payload,
        ...updateData?.payload,
      };
    }
  }
  return JSON.parse(JSON.stringify(data));
}

export default ProfileReducer;
