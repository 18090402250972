import {
  SmallBlockIcon,
  SmallShortListIcon,
} from "../../components/atoms/CommonIcon";
import { ACTIVITY_CENTER, SHORTLIST } from "../../routing/routes.constant";

export const TAB_MAP = {
  RECEIVED: {
    route: `${ACTIVITY_CENTER.fullUrl}/received-all`,
  },
  ACCEPTED: {
    route: `${ACTIVITY_CENTER.fullUrl}/accepted-all`,
  },
  SENT: {
    route: `${ACTIVITY_CENTER.fullUrl}/sent-all`,
  },
  DECLINED: {
    route: `${ACTIVITY_CENTER.fullUrl}/declined-all`,
  },
  SHORTLISTED: {
    route: `${ACTIVITY_CENTER.fullUrl}/shortlisted`,
  },
  BLOCKED: {
    route: `${ACTIVITY_CENTER.fullUrl}/blocked-profile`,
  },
};

export const ACTIVITY_SUB_TAB = [
  {
    name: "Received",
    route: TAB_MAP.RECEIVED.route,
    type: "RECEIVED",
    action: "ALL",
    id: "received-all",
  },
  {
    name: "Accepted",
    route: TAB_MAP.ACCEPTED.route,
    type: "ACCEPTED",
    action: "ALL",
    id: "accepted-all",
  },
  {
    name: "Sent",
    route: TAB_MAP.SENT.route,
    type: "SENT",
    action: "ALL",
    id: "sent-all",
  },
  {
    name: "Declined",
    route: TAB_MAP.DECLINED.route,
    type: "DECLINED",
    action: "ALL",
    id: "declined-all",
  },
  {
    name: "Shortlisted",
    route: TAB_MAP.SHORTLISTED.route,
    id: "shortlisted",
    action: "GIVE",
    type: "SHORTLISTED",
    isHide: true,
    isDivider: true,
  },
  {
    name: "Blocked",
    route: TAB_MAP.BLOCKED.route,
    action: "GIVE",
    type: "BLOCKED",
    id: "blocked-profile",
    isHide: true,
    isDivider: true,
  },
];

export const MOBILE_TABS = [
  {
    name: "Shortlisted",
    description: "Manage Your Shortlisted Profiles",
    icon: <SmallShortListIcon />,
    route: SHORTLIST.fullUrl,
  },
  {
    name: "Blocked Contacts",
    description: "Manage and View Blocked Profiles",
    icon: <SmallBlockIcon />,
    route: `${ACTIVITY_CENTER.fullUrl}/blocked-profile`,
  },
];

export const DEFAULT_ACTIVITY_CENTER_SECTION = {
  ACCEPTED: {
    title: "Accepted Interests",
    description: "Follow up by starting a conversation!",
    route: `${ACTIVITY_CENTER.fullUrl}/sent-accepted`,
  },
  RECEIVED: {
    title: "Interests Received",
    description: "Profiles interested in you. Connect and explore!",
    route: `${ACTIVITY_CENTER.fullUrl}/received-pending`,
  },
  PROFILE_VISIT: {
    title: "Profile Visitors",
    description: "Profiles Might Be Interested in You!",
  },
};
