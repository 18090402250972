import { useNavigate, useSearchParams } from "react-router-dom";

function useRedirection() {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const redirectToUrl = (url) => {
    navigate(url);
  };

  function getSearchParams() {
    const allParams = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      allParams[param] = value;
    }
    return allParams;
  }

  return {
    navigate,
    redirectToUrl,
    searchParams,
    setSearchParams,
    getSearchParams: getSearchParams(),
  };
}
export default useRedirection;
